import { requireFun } from '@/utils'

export default [
  {
    label: 'LOGO',
    prop: 'logo',
    name: 'logo',
    colNum: 18,
    slotName: 'logo'
  },
  {
    label: '平台名称',
    hidden: true,
    prop: 'name',
    name: 'name',
    colNum: 18,
    placeholder: '请输入平台名称',
    rule: [
      {
        required: true,
        message: requireFun('平台名称')
      }
    ]
  },
  {
    label: '是否支持API同步',
    hidden: true,
    prop: 'isDockApi',
    name: 'isDockApi',
    colNum: 18,
    placeholder: '请选择',
    slotName: 'isDockApiSlot'
  },
  {
    label: '是否支持订单导入',
    hidden: true,
    prop: 'isImportWaybill',
    name: 'isImportWaybill',
    colNum: 18,
    placeholder: '请选择',
    slotName: 'isImportWaybillSlot'
  }
]
